@import url(https://fonts.googleapis.com/css?family=Muli:100,400,700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Muli",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin-bottom: 15px;
  text-align: justify;
}

a {
  color: white;
  text-decoration: none;
  opacity: 0.8;
}

a:hover {
  opacity: 1;
}

.btn {
  background: #E63737;
  color: white;
  font-weight: 600;
  padding: 15px 45px;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.btn:hover {
  box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.btn:active, .btn:focus {
  outline: none;
}

.responsive-img {
  width: 75%;
  border-radius: 50%;
}

.wrapper {
  display: flex;
  color: white;
}

.main {
  flex: 1 1;
  background: #242424;
  height:100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


section {
  padding: 15px 50px;

}

.section-header {
  display: block;
  width: 100%;
}



input[type=text], input[type=email], textarea {
  font-family: "Muli";
  background: rgba(255,255,255,0.2);
  border: none;
  padding: 10px;
  border-radius: 5px;
  display: block;
  width: 100%;
  margin-bottom: 15px;
  color: rgba(255,255,255,0.25);
}



ul {
  list-style: none;
}

ul > a > li {
  display: block;
  background: rgba(255,255,255,0.2);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
}

@media (max-width:768px) {

  .wrapper{
    display: block;
  }

  .main {
    display: block;
    height: unset;
    overflow: hidden;
  }
  section {
    padding: 15px 15px;
  }

  .btn {
    width: 100%;
  }


}
.App-header {
    background: -webkit-gradient(linear, left top, right top, from(#FF9160),color-stop(#E63737), color-stop(#C42776), to(#810073));
    background: -webkit-linear-gradient(left, #FF9160,#E63737, #C42776, #810073);
    background: linear-gradient(to right, #FF9160,#E63737, #C42776, #810073);
    padding: 15px 25px;
    height: 30vh;
}

@media (max-width:768px) {
    .App-header {
        max-height: 5px;
        overflow: hidden;
        padding: 0px;
    }
  }

.nav {
    background: #151515;
    overflow: hidden;
    height: 100vh;
    min-width: 200px;
    max-width: 20%;
    text-align: center;
    padding: 15px 25px;
  
  }
.nav-text > p {
  text-align: center;
}
@media (max-width:768px) {
  .nav {
    display: block;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;  
    min-width: 100%;
    overflow: hidden;
  }

  .profile-img {
    display: none;
    
  }

  .nav-text {
    display: none;
  }
}

.project{
    flex: 1 0 30%;
    background: rgba(0,0,0,0.2);
    border: 5px solid #242424;
    border-radius: 15px;
    object-fit: cover;
    
}

.project > .project-content {
    padding: 15px 25px;
    
}

.project > .project-content > .title {
    text-transform: uppercase;
}

.project-img {
    width: 100%;
    border-radius: 15px;
}


@media (max-width:768px) {
    .project {
      display: block;
      min-width: 100%;
      border: none;
      margin-bottom: 10px;
    }
  }
.footer {
    background: rgb(29, 29, 29);
    padding: 2em;
}
