@import url('https://fonts.googleapis.com/css?family=Muli:100,400,700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Muli",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin-bottom: 15px;
  text-align: justify;
}

a {
  color: white;
  text-decoration: none;
  opacity: 0.8;
}

a:hover {
  opacity: 1;
}

.btn {
  background: #E63737;
  color: white;
  font-weight: 600;
  padding: 15px 45px;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  transition: 0.7s;
}

.btn:hover {
  box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
  transform: translateY(-2px);
}

.btn:active, .btn:focus {
  outline: none;
}

.responsive-img {
  width: 75%;
  border-radius: 50%;
}

.wrapper {
  display: flex;
  color: white;
}

.main {
  flex: 1;
  background: #242424;
  height:100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


section {
  padding: 15px 50px;

}

.section-header {
  display: block;
  width: 100%;
}



input[type=text], input[type=email], textarea {
  font-family: "Muli";
  background: rgba(255,255,255,0.2);
  border: none;
  padding: 10px;
  border-radius: 5px;
  display: block;
  width: 100%;
  margin-bottom: 15px;
  color: rgba(255,255,255,0.25);
}



ul {
  list-style: none;
}

ul > a > li {
  display: block;
  background: rgba(255,255,255,0.2);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
}

@media (max-width:768px) {

  .wrapper{
    display: block;
  }

  .main {
    display: block;
    height: unset;
    overflow: hidden;
  }
  section {
    padding: 15px 15px;
  }

  .btn {
    width: 100%;
  }


}