.App-header {
    background: linear-gradient(to right, #FF9160,#E63737, #C42776, #810073);
    padding: 15px 25px;
    height: 30vh;
}

@media (max-width:768px) {
    .App-header {
        max-height: 5px;
        overflow: hidden;
        padding: 0px;
    }
  }
