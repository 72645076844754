.nav {
    background: #151515;
    overflow: hidden;
    height: 100vh;
    min-width: 200px;
    max-width: 20%;
    text-align: center;
    padding: 15px 25px;
  
  }
.nav-text > p {
  text-align: center;
}
@media (max-width:768px) {
  .nav {
    display: block;
    height: fit-content;  
    min-width: 100%;
    overflow: hidden;
  }

  .profile-img {
    display: none;
    
  }

  .nav-text {
    display: none;
  }
}
